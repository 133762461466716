import React, { useEffect, useState } from "react"
import { css } from "@emotion/core"
import { connect } from "react-redux"
import { navigate } from "gatsby"

import {
  LayoutQuoting,
  Title,
  SectionCenter,
  PlanDisplayCard,
  PrimaryButton,
  GoBack,
} from "../../components"
import { getCurrentPath } from "../../redux/actions"

const Fail = ({ location, getCurrentPath, selectedPlan, apiCallStatus }) => {
  const [isValidPlan, setIsValidPlan] = useState(true)

  useEffect(() => {
    getCurrentPath(location.pathname)
    setIsValidPlan(selectedPlan !== null)
  })

  return (
    <LayoutQuoting>
      <section
        css={css`
          padding: 5rem 0;
        `}
      >
        {isValidPlan ? (
          <>
            <Title
              title="Oops... Something went wrong"
              fontColor="var(--clr-primary-300)"
              barColor="var(--clr-primary-400)"
            />
            <SectionCenter>
              <div
                css={css`
                  display: grid;
                  row-gap: 1.5rem;
                  justify-items: center;
                `}
              >
                <p
                  css={css`
                    max-width: 700px;
                    margin-bottom: 1rem;
                    font-size: 1rem;
                    font-weight: bold;
                    letter-spacing: var(--letter-spacing-sm);
                    line-height: 1.75;
                  `}
                >
                  {`We are unable to process your payment for the plan shown below. Please contact your broker to complete the transaction.`}
                </p>
                {apiCallStatus && apiCallStatus.errorReason && (
                  <p
                    css={css`
                      max-width: 700px;
                      margin-bottom: 2rem;
                      font-size: 1rem;
                      font-weight: bold;
                      letter-spacing: var(--letter-spacing-sm);
                      line-height: 1.75;

                      span {
                        color: var(--clr-primary-400);
                      }
                    `}
                  >
                    The possible reason of this failure might be:{" "}
                    <span>{apiCallStatus.errorReason}</span>
                  </p>
                )}
                {selectedPlan && (
                  <PlanDisplayCard
                    noChangeBtn
                    borderColor="var(--clr-primary-400)"
                    boxShadow="var(--solid-shadow-primary400)"
                  />
                )}
              </div>
              <div
                css={css`
                  display: flex;
                  justify-content: center;
                  margin-top: 4rem;
                `}
              >
                <PrimaryButton
                  textSize="0.875rem"
                  textTransform="capitalize"
                  maxWidth="none"
                  margin="0"
                  onClick={() => navigate("/")}
                >
                  return
                </PrimaryButton>
              </div>
            </SectionCenter>
          </>
        ) : (
          <GoBack
            backToLink="/get-quote-policies"
            notFilledOut="plan"
            text="Looks like you have not selected a "
            actionText="go back to select one."
          />
        )}
      </section>
    </LayoutQuoting>
  )
}

const mapStateToProps = ({ selectedPlan, apiCallStatus }) => ({
  selectedPlan,
  apiCallStatus,
})

export default connect(mapStateToProps, { getCurrentPath })(Fail)
